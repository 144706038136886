<template>
  <div>
    <page-breadcrumb title="Report" class="mb-2" />
    <div class="d-flex align-items-center">
      <form-generator :model="filter" :schema="filter_schema" />
    </div>
    <data-table-ssr id="group_list" ref="group_list" :columns="fields" :get-list-fn="getReport">
      <template #slot_recording="{slotData}">
        <a v-if="slotData.class_recording_url" :href="slotData.class_recording_url" style="text-decoration: underline;" target="_blank">Record</a>
      </template>
    </data-table-ssr>
  </div>
</template>
<script>
const fields = [
  { label: 'Class Time', field: 'class_time', value_handler: (model) => { return `${model.room_info ? model.room_info?.date : ''} ${model.room_info ? model.room_info?.time : ''}` } },
  { label: 'Teacher Email', field: 'teacher_email' },
  { label: 'Student Email', field: 'student_email' },
  { label: 'Pronun', field: 'score_pronun' },
  { label: 'Vocab', field: 'score_vocab' },
  { label: 'Fluency', field: 'score_fluency' },
  { label: 'Avg. Rating', field: 'score_avg' },
  { label: 'Comment - Pronun', field: 'comment_pronun' },
  { label: 'Comment - Vocab', field: 'comment_vocab' },
  { label: 'Comment - Fluency', field: 'comment_fluency' },
  { label: 'Recording', field: 'recording', input_type: 'slot'},
  { label: 'Course', field: 'course' },
  { label: 'Lesson', field: 'lesson' },
];
const teacher_email_options = []
const filter_schema = [
  {
    cols: 3, fields: [{ field: 'start_date', label: 'Start Date', input_type: 'date' }]
  },
  {
    cols: 3, fields: [{ field: 'end_date', label: 'End Date', input_type: 'date' }]
  },
  {
    cols: 3, fields: [{ field: 'teacher_email_key', label: 'Teacher email' }]
  },
  {
    cols: 3, fields: [{ field: 'student_email_key', label: 'Student email' }]
  }
]
import service from '../service'
export default {
  data() {
    return {
      fields,
      filter_schema,
      filter: {
        start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end_date: new Date()
      }
    }
  },
  created(){
    // this.getTeacherList();
  },
  watch:{
    filter: {
      handler(){
        if (this.filter.student_email_key){
          this.filter.student_email_key = this.filter.student_email_key.trim();
          this.filter.student_email = {$regex: this.filter.student_email_key, "$options": 'i'}
        }
        else{
          delete this.filter.student_email
        }
        if (this.filter.teacher_email_key){
          this.filter.teacher_email_key = this.filter.teacher_email_key.trim();
          this.filter.teacher_email = {$regex: this.filter.teacher_email_key, "$options": 'i'}
        }
        else{
          delete this.filter.teacher_email
        }
        this.$refs.group_list.getList();
      },
      deep: true
    }
  },
  methods: {
    async getReport({ limit, page, query }) {
      let response_data = await service.getTeacherRatingStudentReport({
        filter: JSON.stringify(this.filter),
        limit,
        page
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async getTeacherList(){
      let response_data = await service.getTeacherList();
      if (response_data){
        let options = response_data.list.map((item) => {return {text: item.email, value: item._id}});
        teacher_email_options.push(...options);
      }
    }
  }
}
</script>